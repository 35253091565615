@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap");
$main: #2181fa;
$main-bg: linear-gradient(90deg, #e95567 0%, #f36735 100%);
$card-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;

@media only screen and (max-width: 1300px) {
  .hero-section .content {
    padding-top: 200px;
    margin-left: 16%;
  }
}
@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 991px) {
  .organzation-outer{
    .id-mail{
      font: 400 16px/120% Poppins, sans-serif;
    }
    .id-content{
      font: 600 16px/120% Poppins, sans-serif;
    }
  }
}

@media only screen and (max-width: 768px) {
  .auth-body .auth-form .signup-btns {
    width: 100%;
  }
  .auth-header {
    min-height: 60px;
    .logo {
      height: 35px;
    }
  }
  .StepperContainer-0-2-1 {
    width: 100%;
    .StepContainer-0-2-2 {
      span {
        font-size: 11px;
      }
    }
  }
  .auth-body .auth-form .fav {
    height: 60px;
  }
  .auth-header {
    justify-content: center;
  }
  .header-row {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .auth-body .auth-form {
    width: 100%;
    padding: 15px;
  }
  .auth-body .auth-form small {
    font-size: 14px;
  }
  .auth-body .auth-form h2 {
    font-size: 24px;
  }
  .auth-body .auth-form h6 {
    font-size: 16px;
  }
  .auth-body h5 {
    font-size: 24px;
  }
  .auth-body .auth-form .field-row {
    width: 100%;
  }
  .auth-body .network-url {
    width: 100%;
  }
  .auth-body .devider {
    padding: 30px 0px 20px;
  }
  .auth-body .devider .xyz {
    width: 300px;
  }
  .auth-body .auth-form .mail-img {
    height: 80px;
  }
  .auth-body .auth-form h6 {
    font-size: 14px;
  }
  .flex-box-two{
    bottom: -40px;
    width: 100%;
    padding: 20px;
    font-size: 14px;
    .fa-circle-xmark:before{
      font-size: 25px;
    }
  }
}



// organzation-v2 responsiveness stsrt here

@media only screen and (max-width: 768px) {
  .organzation-outer{
    .inner{
      width: 100% !important;
  }
  .filter-outer{
    width: 100%;
    height: 42px;
    padding: 14px;
  }
  .input{
    width: 100%;
    margin-top: -50px;
    margin-left: 37px;
    background: none;
  }
  .all{
    margin-top: 10px;
  }
  .id-outer{
    flex-wrap: nowrap;
  }
  .feild{
    margin-left: -16px;
  }

  .id-mail{
    font: 400 12px/120% Poppins, sans-serif;
  }
  .id-content{
    font: 600 12px/120% Poppins, sans-serif;
  }

  .org-filter{
    margin-top: 25px;
    padding: 0px 10px;
  }
  .org-feild{
    // margin-top: 15px;
    // margin-left: 10px;
    margin: 15px 0px 0px 0px;
    padding-left: 10px;
  }
  .id-outer{
    margin-top: 10px;
  }
  }

}

@media only screen and (max-width: 1024px) {
 .all{
  width: 44%;
 }
 .div-9{
  gap: 10px;
 }
}


@media only screen and (max-width: 576px) {
  .organzation-outer{
    .id-mail{
      font: 400 14px/120% Poppins, sans-serif;
    }
    .id-content{
      font: 600 14px/120% Poppins, sans-serif;
    }
  }

}
